
body {
  margin: 0;
  font-family: "Roboto";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-top:84px;
  background:#fff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.bottom-fix{
  position: fixed;
  bottom: 0;
  width: 100%;
  left:0;
}
.btn-full{
  border-radius:0 !important;
}

.page-title{
    background:#eee;
    padding:15px 0;
    margin-bottom:15px;
}
.page-title h1{
    font-weight:bold;
}

.link{
    cursor:pointer;
    transition:0.3s;
}
.link:hover{
    background:#eee;
    color:#4a7b04;
}
.jDHQPG{
    display:block !important;
}

.navbar input{
    border:none;    
    margin-bottom: -4px;
}
.suggest{
    position: absolute !important;
    width: 800px !important;
    background: #fff;
    top: 37px;
    left: 16px;
    box-shadow: 0 0 4px #999;
    border-radius: 10px;
    border-top-left-radius: 0;
}
@media (max-width:900px) {
  .suggest{
    width: 500px !important;
  }
}
@media (max-width:420px) {
  .suggest{
    width: 360px !important;
  }
}
.suggest .rdt_TableRow{
    cursor:pointer;
    transition:all 0.3s;
}
.suggest .rdt_TableRow:hover{
    background:#eee;
    color:#4a7b04;
}
.alert-dark {
  color: #1b1e21;
  background-color: #f8f9fa;
  border-color: #eee;
}
.border-vertical{
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
}
.text-grey{
  color:#ccc;
}
.table-bordered th{
  border:0;
}
.table thead th{
  background-color:#f8f9fa;
  padding: 0.5rem;
  border-color:#eee;

}
.react-bs-table .table-bordered>tbody>tr>td{
  font-size: 14px;
	word-wrap: break-word;
}
.campaignTitle,
.campaignTitle a{
  color: #26d988; 
  /* color: #4d820b; */
  text-transform: uppercase;
}
.table thead th.h-border{
  border-left:1px solid #eee;
  border-right:1px solid #eee;
}
.table thead th.l-border{
  border-left:1px solid #eee;
}
.table tbody td{
  
  font-weight: 500;
}
.verticalAlignSub{
  vertical-align: sub !important;
}

.inputRead{
  width: 100%;
  padding: 2px; 
  font-size: 12px;
}
.breadcrumb-item a{
  font-weight: bold;
  color:#000;
}
.react-bs-table-bordered{
  border-radius:0 !important;
}
.react-bs-table-sizePerPage-dropdown .btn{
  padding: 5px 15px;
  margin-top: 0;
}

.pagination > .page-item.active > a, .pagination > .page-item.active > a:focus, .pagination > .page-item.active > a:hover, .pagination > .page-item.active > span, .pagination > .page-item.active > span:focus, .pagination > .page-item.active > span:hover {
  /* background-color: #81d908;
  border-color: #81d908; */
  background-color: #25d988;
  border-color: #25d988;
}
.table-row-click tr{
  cursor: pointer;
}

/* material-kit.min.css | http://localhost:3000/assets/css/material-kit.min.css */

.dropdown-menu {
  display: block;
}

.dropdown-menu .dropdown-item, .dropdown-menu li > a {

  min-width: 1rem;
  padding: 0;
}

@media (min-width: 768px) {
  .dropdown-menu .dropdown-item, .dropdown-menu li > a {

    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
}

.dropdown-menu {
  min-width: 2rem;
}

.dropdown-menu .dropdown-item:focus, .dropdown-menu .dropdown-item:hover, .dropdown-menu a:active, .dropdown-menu a:focus, .dropdown-menu a:hover {
  /* background-color: #81d908; */
  background-color: #25d988;
}
.react-bs-table table td, .react-bs-table table th{
  white-space: initial !important;
  text-overflow: initial !important;
}

.react-bs-table-container .react-bs-table-search-form {
  border-radius: 50px;
  border: 1px solid #ccc;
  padding: 5px 15px;
}

.form-group-sm > input:nth-child(1) {
  border: none;
  background: none;
  line-height: normal;
  height: auto;
}


.togglebutton label input[type="checkbox"]:checked + .toggle {
  /* background-color: rgba(36, 206, 10, 0.7); */
  background-color: #25d988;
}

.togglebutton label input[type="checkbox"]:checked + .toggle::after {
  /* border-color: #52b027; */
  border-color:  #25d988;
 
}
.card-header{
  padding: 0;
}
.card-header .card-title{
  margin: 0;
  padding: 5px;
}
.text-default{
  color:#999;
}
strong, .strong{
  font-weight: 700;
}
.text-success-dark{
  /* color:#4d820b; */
  color:#25d988;
}

.modal-body {
  max-height:550px; 
  overflow-y: auto;
  
}
/* #pageDropDown{
  display: none !important;
} */
input[type="color"] {
	/* -webkit-appearance: none; */
	/* border: none; */
	width: 40px;
	height: 50px;
}

.fa-square {
  font-size: 30px;
  padding: 0px;  
  border: 1px solid black;
  border-radius: 10px;
  
}

.square{
  
  border: 1px solid #000;
  /* width: 26px;
  height: 26px; */
  padding:8px 15px ;
 
}
.text-orange{
  color: #ff6600;
}


.sort-column{
  position: relative;
}
.order{
	position: relative;
	bottom: 0;
	right:0;
	margin: 5px !important;
}
.order .caret{
	font-size: 7px;
}
.order .caret:after{	
	content: "▼";
}
.order .dropup .caret:after,
.order.dropup .caret:after{
	content: "▲";
}
