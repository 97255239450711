.card .card-header.card-header-avatar{
    width: 140px;
    display: flex;
    align-content: center;
    justify-content: center;
    flex-direction: column;
    z-index: 10;
    background:#fff;
    padding:0;
}
.card-header-avatar img{
    display: flex;
}
.header-filter{
    transition: all 1s;
    background-size: cover;
}
.login-page{
    padding-top: 0;
    background: #fff !important;
}
.card-login{
    margin:0;
}
.btn-submit-login{
    margin-top:-10px;
}
.card-login a{
    font-size:12px;
}