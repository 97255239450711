.react-datepicker-wrapper{
    flex:1
}
.form-row .btn-sm{
    padding:5px;
    margin-bottom: 17px;
}
#status .card .card-header{
    margin: 0 0 40px 0;
}
.card-header-icon{
    display: flex;
}
.card-icon{
    align-items: center;
    flex: 1;
    display: flex;
    justify-content: center;
}
.card-stats .card-category{
    margin-bottom: 0;
}
.card-stats .card-title{
    margin-top:0;
    
}

.card-status{
    align-items:center;
    flex-grow: 1;
}
.gap-2{
	gap: 0.5rem;
}
